<template>
  <div class="why-us py-0">
    <div class="row mx-0">
      <div class="col-md-6 pl-md-0 image--w100 px-0 home-before-after mx-auto" v-if="beforeAfter?.before">
        <BeforeAfter :before="beforeAfter?.before" :after="beforeAfter?.after"/>
      </div>
      <div class="col-md-6 align-items-center d-flex px-0 mx-auto" v-if="text">
        <div class="col-md-10 mr-auto pl-md-5 pt-md-0 pt-4">
          <div class="container">
            <h4 class="text-center title-h2 mb-4">{{title}}</h4>
            <div class="text" v-html="text"></div>
            <div class="text-center my-5">
              <v-btn  color="red" class="mx-auto" :href="parseLink(url)">
                התאימו לי שטיח
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts" setup>

const {currentPage} = useGlobalStore();
const {title,text,url,beforeAfter} = currentPage?.template?.homepage?.trunkSection;


</script>
<style lang="scss" >
.home-before-after{
  .before-after-container {
    display: flex;
    img{
      height: auto;
    }

  }
}
</style>
